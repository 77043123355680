.container {
  display: flex;
  flex-direction: column;
  width: 790px;
}

.title {
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px #d7d7d7 solid;
}

.firstContain {
  display: flex;
  flex-direction: row;
}

.secondContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.radioButton div {
  flex-direction: row-reverse;
}

.firstRadioContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-right: 150px;
}

.secondRadioContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-right: 200px;
}

.containerInputs {
  max-width: 300px;
}

.inputs {
  justify-content: flex-start;
  width: 333px;
  padding: 50px 20px 0;
  margin: 0 10px;
}

.categories {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  margin-top: 40px;
  margin-left: 8px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 0 20px 20px;
  margin-right: 15px;
}

.inputs p {
  margin-bottom: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 10%;
}

.table {
  width: 90%;
}

.outlet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
}

.header {
  position: fixed;
  z-index: 20;
  display: flex;
  width: 100%;
  color: #ffffff;
  background-color: #373867;
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 18px 50px;
  background-color: #373867;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}

.brand :nth-child(2) {
  margin-right: 5px;
  margin-left: 25px;
  font-weight: 600;
}

.headerLogo img {
  width: 15px;
  margin-top: 5px;
}

.navbar {
  display: flex;
  align-items: center;
  margin-right: 100px;
  font-size: 13px;
  font-weight: bold;
}

.menuLogoContainer img {
  display: inline-block;
  width: 11px;
}

.menu {
  position: fixed;
  top: 60px;
  left: 76px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  width: 190px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-size: 14px;
  background-color: #373867;
  border-radius: 0 0 4px 4px;
}

.menu a,
.menu span {
  width: 100%;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.arrow {
  padding-right: 20px;
  padding-left: 10px;
  color: transparent;
}

.brand:hover .arrow {
  display: inline;
  color: white;
  transition: 0.1s;
}

.radiumMenuSpan {
  font-weight: 600;
}

.logout button {
  color: white;
}

.logout button:hover {
  color: #5bc59b;
}

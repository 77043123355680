.container {
  display: flex;
}

.containerMain {
  display: flex;
  flex-direction: column;
}

.containerMain div {
  align-items: center;
  justify-content: center;
}

.fontStyle {
  font-size: 12px;
}

.textHeader {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 6px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.values {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.text {
  width: 130px;
  font-size: 16px;
  font-weight: bold;
  color: #373867;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 10px;
  background-color: #373867;
}

.header div {
  color: white;
}

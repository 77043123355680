.container {
  width: 560px;
  padding: 20px;
}

.button {
  text-align: end;
}

.text {
  display: flex;
  flex-direction: row;
}

.text svg {
  margin-top: 18px;
  margin-left: 18px;
}

.container {
  display: flex;
  flex-direction: row;
}

.title {
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px #d7d7d7 solid;
}

.containerInputs {
  display: flex;
  flex-direction: row;
}

.inputs {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 0 20px 20px;
}

.chip {
  padding: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 560px;
  min-height: 140px;
  padding: 20px;
}

.button {
  display: flex;
  justify-content: flex-end;
}

.button div {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
}

.text {
  display: flex;
  flex-direction: column;
}

.loaderAndText > * {
  height: 20px;
  margin: 1rem;
}

.loader {
  margin: 0 1rem;
}
